import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import { H3Style, H5Style } from "../Core/Styles/typography"

const Block = styled.div`
  padding: 0 24px;
  padding-top: 24px;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 30px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 0 76px;
    padding-top: 30px;
    margin: auto;
  }
`

const Heading = styled.h1``

const Text = styled.p`
  ${H3Style};
  padding-top: 24px;
  padding-bottom: 60px;

  @media (min-width :${breakpoints.md}) {
    max-width: 540px;
    padding-top: 18px;
  }
`

const Subheading = styled.h2`
  ${H5Style};
  font-weight: 600;
  color: ${colors.greyDarker};
  padding-bottom: 12px;

  @media (min-width :${breakpoints.md}) {
    padding-bottom: 24px;
  }
`

const StyledLink = styled(Link)`
  display: block;
  width: fit-content;
  color: ${colors.greyDark};
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;

  @media (min-width :${breakpoints.md}) {
    font-size: 18px;
    line-height: 28px;
  }
`

const Block404 = () => {
  return (
    <Block>
      <Heading>Oops</Heading>
      <Text>
        We can’t seem to find the page you are looking for
      </Text>
      <Subheading>Here are some useful links instead</Subheading>
      <StyledLink to="/">Home</StyledLink>
      <StyledLink to="/">Work</StyledLink>
      <StyledLink to="/about">About</StyledLink>
      <StyledLink to="/">Contact</StyledLink>
    </Block>
  )
}

export default Block404
