import React from "react"
import Layout from "../components/Layout/Layout"
import BlockContainer from "../components/Core/Containers/BlockContainer"
import Block404 from "../components/404/Block404"

const NotFoundPage = () => {
  return (
    <Layout>
      <BlockContainer>
        <Block404 />
      </BlockContainer>
    </Layout>
  )
}

export default NotFoundPage
